import React from 'react';
import { connect } from 'react-redux';
import Img from "gatsby-image"
import Layout from '../components/layout';
import SEO from '../components/seo';
import DepthLayer from '../components/parallax/DepthLayer';
import { updateCursor, updateWindow } from '../state/state-movement';
import { graphql } from 'gatsby';
import Gallery from '../svg/gallery-icon.svg'
import Carousel, { Modal, ModalGateway } from "react-images";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import renderHTML from 'react-render-html';
import GridGallery from 'react-photo-gallery';
import Logo from '../svg/white-logo.svg'
import Arrow from '../svg/left-arrow.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink";

class GalleryPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contentActive: false,
      galleryActive: false,
      currentImage: 0,
      modalOpen: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({contentActive: true});
    }, 100);
    this.buildGalleryImages();
  }

  openLightbox = (e,imageObj) => {
    
    this.setState({
      modalOpen: true,
      currentImage: imageObj.index
    })
  };

  closeLightbox = () => {
    this.setState({
      modalOpen: false,
      currentImage: 0
    })
  };

  buildGalleryImages = () => {
    console.log(this.props.data.gallery.galleryImagesAndVideo)
    let media = this.props.data.gallery.galleryImagesAndVideo;
    this.galleryMedia = [];

    for (var i = 0; i < media.length; i++) {
       let thisMedia = {};
      if (media[i].__typename == "DatoCmsGalleryImage") {
        thisMedia = {
          url: media[i].image.url,
          src: media[i].image.url,
          type: 'photo',
          altTag: media[i].image.alt == null ? "" : media[i].alt,
          width: media[i].image.width * 0.1,
          height: media[i].image.height * 0.1,
        }
      } else {
        let image = media[i].videoThumbnail !== null ? media[i].videoThumbnail.url : `https://img.youtube.com/vi/${media[i].youtubeId}/maxresdefault.jpg`;
        let width = media[i].videoThumbnail !== null ? media[i].videoThumbnail.width * 0.1 : 192;
        let height = media[i].videoThumbnail !== null ? media[i].videoThumbnail.height * 0.1 : 108;
        thisMedia = {
          url: `https://www.youtube.com/embed/${media[i].youtubeId}`,
          src: image,
          type: 'video',
          altTag: "",
          width: width,
          height: height,
        }
      }
      this.galleryMedia.push(thisMedia);
    }
    this.setState({
      galleryActive: true
    })
  }

  render () {
    let data = this.props.data.gallery;
    let depth = this.props.depth;
    let movement = this.props.movement;

    return (
      <>
        <SEO title="Gallery" bodyType="" description={data.seoDescription}/>
        <Layout 
          transitionStatus={this.props.transitionStatus}
          transitionDirection={this.props.current.state.direction}
          class={`gallery`}
        >
          <div className={`content ${this.state.contentActive && 'content-active'}`}>
            <div className="content-header">
              <div className="content-header__icon">
                <Gallery />
              </div>
              <h1 className="content-header__title">Gallery</h1>
            </div>
            <div className="content-body">
              {this.state.galleryActive && 
                <>
                  <GridGallery photos={this.galleryMedia} onClick={(e, obj) => this.openLightbox(e,obj)}/>
                    <ModalGateway>
                      {this.state.modalOpen ? (
                        <Modal onClose={this.closeLightbox}>

                        <ReactImageVideoLightbox
                                data={this.galleryMedia}
                                startIndex={this.state.currentImage}
                                showResourceCount={true}
                                onCloseCallback={this.closeLightbox} 
                                  
                        />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                </>
              }
              <div className="content-body__bottom-link gallery">
                <AniLink to={`/collaboration`} fade duration={1}>
                  <span className="content-link__text">I want some of this cool stuff at my party!</span>
                  <Arrow/>
                </AniLink>
              </div>              
            </div>
            <Logo className="content-logo gallery"/>
          </div>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    gallery: datoCmsGallery {
      seoDescription
      galleryImagesAndVideo {
        ... on DatoCmsGalleryImage {
          image {
            alt
            url
            width
            height
          }
        }
        ... on DatoCmsGalleryVideo {
          youtubeId
          videoThumbnail {
            alt
            url
            width
            height
          }
        }
      }
    }
  }
`

export default connect(
  state => ({ 
    window: state.movementState.windowDimensions,
    depth: state.movementState.depthLevels,
    movement: state.movementState,
  }),
  dispatch => ({ 
    updateCursor: cursorObj => dispatch(updateCursor(cursorObj)),
    updateWindow: windowObj => dispatch(updateWindow(windowObj)),
  }),
)(GalleryPage);